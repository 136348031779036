//require('../../../../wdh-feature/src/js/rich-text-list.js');

(function ($) {
  function richTextHeightCalc() {
    var richTextWidth = $('.content .component.rich-text-list .component-content ul > li').outerWidth();
    $('.content .component.rich-text-list .component-content > ul > li').css('height', richTextWidth);
  }
  richTextHeightCalc();
  function calcRichTextListPadding() {
    var richTextPadding = $('.footer .component.rich-text-list .component-content').css('margin-left');
    $('#header .component.image:first-of-type .component-content img').css('left', richTextPadding);
    $('#footer .component.image').css('left', richTextPadding);
  }
  calcRichTextListPadding();
  $(window).resize(function () {
    calcRichTextListPadding();
    richTextHeightCalc();
  });
})(jQuery);