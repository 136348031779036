import { onResize } from '../../../../wdh-foundation/wdh';
(function ($) {
  var Level2Container = $('<div class="level2-wrapper"><div class="level2-container"></div></div>');
  var tmpurl = '';
  var $navLi = $('.component.navigation ul li');
  $navLi.each(function () {
    var $this = $(this);
    var $a = jQuery(' > a', $this),
      href = $a.attr('href'),
      sIndex = href.length,
      section = href.substr(0, sIndex - 1),
      location = window.location.href;
    if (location.indexOf(section) > -1) {
      tmpurl = href;
      var level2ul = $('ul', $this);
      if (level2ul.length > 0) {
        // creates container for level 2 ul.
        $("#header").append(Level2Container);
        $("#header .row").addClass('with-sub-navigation');
        // moves level 2 ul into created container
        level2ul.appendTo('.level2-container');
        $('.level2-container').addClass('level2con-active');
      }
      $a.addClass('active');
    }
  });
  $('.mobile-nav').on('click', function () {
    $('.component-content ul').toggleClass('menu-open');
    $('.mobile-nav').toggleClass('menu-open');
    calcBurgerMenuPosition();

    // Prevent scrolling happens on the body while burger menu is open.
    // This results in the user being at the same location on the main page when they close the burger menu.
    setTimeout(function () {
      if ($('.component-content ul').hasClass('menu-open')) {
        $("body").css({
          height: "0",
          overflow: "hidden"
        });
      } else {
        $("body").css({
          height: "auto",
          overflow: "visible"
        });
      }
    }, 100);
  });
  function calcImagePosition() {
    var $image = $('#header .component.intro-banner:last-of-type');
    $image.css('padding-top', "".concat(Math.floor($('#header .component.navigation').outerHeight()), "px"));
  }
  function calcLevel2Position() {
    var $level2MobilePosition = $('#header .component.intro-banner:last-of-type').outerHeight(); // - $('#header .level2-container').outerHeight();
    if ($(window).width() < 768 && $('.level2-container').hasClass('level2con-active')) {
      $('.level2-wrapper').css('top', $level2MobilePosition);
    }
  }
  function calcBurgerMenuPosition() {
    var $BurgerMenuPosition = $('#header .component.navigation').outerHeight();
    if ($(window).width() < 768 && $('.component.navigation ul').hasClass('menu-open')) {
      $('#header .component.navigation .component-content > ul.menu-open').css('top', $BurgerMenuPosition);
    }
  }
  onResize(function () {
    calcImagePosition();
    //calcLevel2Position();
    calcBurgerMenuPosition();
  });
})(jQuery);