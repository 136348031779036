require('../../../../wdh-feature/wdh-blog/src/js/wdh-blog.js');
require('../../../../wdh-feature/wdh-blog/src/js/wdh-categories.js');
(function ($) {
  //Changes order of news-date and news-title in the DOM on blog spots
  $('.component.blog-list #blog-list li, .component.latest-blog-posts #blog-list li, .component.related-blogs li').each(function () {
    $('.news-date', $(this)).insertAfter($('.news-title', $(this)));
  });
  $('.component.blog .blog-image').insertAfter('.component.blog .component-content');

  // Creates new <p> tag with class="button-container" that should contain any <a> tags
  var p_tag_button = $('<p class="button-container"></p>');
  $(".blog-content").append(p_tag_button);

  // Moves any <a> tag in a existing <p> tag into the newly created <p> tag, with the class "button-container"
  // After moving any <a> tag, the original <p> tag will be empty and therefore removed from the DOM.
  $('.blog-content p').each(function () {
    $('.like-button').appendTo('.button-container');
    $('p:empty').remove();
  });
  var topPadding = $('.header .component.navigation').outerHeight();
  if ($('.intro-banner').length == 0) {
    $('.component.blog').css('padding-top', topPadding);
  }
  ;
})(jQuery);